import React from "react";
import Hero from "../components/sections/Hero";
import Services from "../components/sections/Services";
import RecentWork from "../components/sections/RecentWork";
import Clients from "../components/sections/Clients";
import FullPageLoader from "../components/common/FullPageLoader";
import Header from "../components/sections/Header";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import thumbnail from "../thumbnails/thumbnail.png";

const animDuration = 0.7;

export default class HomePage extends React.Component {
  state = {
    loaded: false
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 1000);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Winner | Creative & Web Development Agency</title>
          <meta
            name="description"
            content="We design and develop custom web apps, from personal blogs and corporate websites to powerful backend applications."
          />
          <meta
            name="keywords"
            content="winner, winner agency, web app, websites, development, web development, design, node, nodejs, react, reactjs, wordpress, graphic design, product design, creative design, custom development, seo, php, javascript"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Winner | Creative & Web Development Agency" /> 
          <meta property="og:image" content={thumbnail} />
          <meta property="og:image:secure_url" content={thumbnail} />
          <meta property="og:image:width" content="700" />
          <meta property="og:image:height" content="362" />
          <meta property="og:description" content="We design and develop custom web apps, from personal blogs and corporate websites to powerful backend applications." />
          <link rel="canonical" href="https://winner.agency" />
        </Helmet>
        <CSSTransition
          in={!this.state.loaded}
          timeout={500}
          classNames="my-node"
          unmountOnExit
        >
          <FullPageLoader />
        </CSSTransition>
        <div className="home-page">
          <Header white={false} />
          <Hero animDuration={animDuration} />
          <Services animDuration={animDuration} />
          <RecentWork animDuration={animDuration} />
          <Clients animDuration={animDuration} />
        </div>
      </React.Fragment>
    );
  }
}
