import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import productDesign from "../../img/product_design.svg";
import webDevelopment from "../../img/web_development.svg";
import graphicsdesign from "../../img/graphics_design.svg";

class Services extends React.Component {
  render() {
    return (
      <section id="services" className="home-section">
        <ScrollAnimation
          duration={this.props.animDuration}
          animateIn="fadeInUp"
        >
          <div className="container">
            <h3 className="section-title">Services</h3>
          </div>
          <div id="services__area">
            <div className="container">
                <div className="service" onClick={event => window.location.href='/services#product'}>
                  <div className="service__content">
                    <img src={productDesign} alt="Product Design" />
                    <h4>Product Design</h4>
                  </div>
                </div>
              <div className="service" onClick={event => window.location.href='/services#web'}>
                <div className="service__content">
                  <img src={webDevelopment} alt="Web Development" />
                  <h4>Web Development</h4>
                </div>
              </div>
              <div className="service" onClick={event =>  window.location.href='/services#graphic'}>
                <div className="service__content">
                  <img src={graphicsdesign} alt="Graphic Design" />
                  <h4>Graphic Design</h4>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default Services;
