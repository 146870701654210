import React from "react";

export default class Client extends React.Component {
  render() {
    return (
      <div className="client">
        <a
          target="_blank"
          without="true"
          rel="noopener noreferrer"
          href={this.props.url}
        >
          <img src={this.props.logo} alt={this.props.title} />
        </a>
      </div>
    );
  }
}
