import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import CaseStudyTextBlock from "../components/sections/CaseStudyTextBlock";
import CaseStudyVideoBlock from "../components/sections/CaseStudyVideoBlock";
import CaseStudyImageBlock from "../components/sections/CaseStudyImageBlock";
import tfFeatured from "../img/casestudies/tokenfest/tf-featured.png";
import tf1 from "../img/casestudies/tokenfest/tf-mockup-1.png";
import tf2 from "../img/casestudies/tokenfest/tf-mockup-2.png";
import tf3 from "../img/casestudies/tokenfest/tf-mockup-3.png";
import tf4 from "../img/casestudies/tokenfest/tf-mockup-4.png";
import tf5 from "../img/casestudies/tokenfest/tf-mockup-5.png";
import Header from "../components/sections/Header";
import { Helmet } from "react-helmet";
import thumbnail from "../thumbnails/tokenfest.png";

const TokenfestPage = () => {
  return (
    <React.Fragment>
      <Helmet>
          <title>Token Fest - Case Study</title>
          <meta
            name="description"
            content="Our task was to design and develop a website that will initialy serve as landing page for marketing purposes, but later we extended it’s functionalities..."
          />
          <meta
            name="keywords"
            content="wordpress, php, events website, boston, web development, winner"
          />
          <meta property="og:image" content={thumbnail} />
      </Helmet>
      <div className="cs-page">
        <ScrollAnimation animateIn="fadeInDown" offset="0" animateOnce={true}>
          <Header white={true} />
        </ScrollAnimation>
        <header className="casestudy tokenfest-bg tokenfest-color">
          <ScrollAnimation animateIn="fadeInUp">
            <div className="container">
              <div className="cs-header--content">
                <h4>Case Study</h4>
                <h1>What’s common between Blockchain and Wordpress?</h1>
                <div className="cs-infobox">
                  <span>Client</span>
                  <ul>
                    <li>Token Fest</li>
                  </ul>
                </div>
                <div className="cs-infobox-separator"></div>
                <div className="cs-infobox">
                  <span>Services</span>
                  <ul>
                    <li>Web design</li>
                    <li>Web development</li>
                    <li>On-site graphics</li>
                  </ul>
                </div>
              </div>

              <div className="cs-header--featured">
                <img src={tfFeatured} alt="TokenFest Mobile Web" />
              </div>
            </div>
          </ScrollAnimation>
        </header>
        <CaseStudyTextBlock title="About the client">
          Token Fest is an Events and Media company producing the premier
          conference and expo events for the blockchain industry. They bring
          together key stakeholders including institutional investors,
          entrepreneurs, developers, attorneys and business leaders who are using
          blockchain technologies to impact the future. Token Fest had its’
          inaugural conference launched March 15th 2018 at the Place of Fine Arts
          in San Francisco.
          <br />
          <br />
          To find out the whole story behind Token Fest, watch the video below.
        </CaseStudyTextBlock>
        <CaseStudyVideoBlock>
          <iframe
            width="560"
            height="315"
            fov="100"
            src="https://www.youtube.com/embed/AeMY0tLqXak"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </CaseStudyVideoBlock>
        <CaseStudyTextBlock title="Our role in the chain">
          We’ve partnered up with Token Fest in it’s early days when it was
          cruical to attract biggest companies in the industry in order to raise
          funds for their very first conference. Our task was to design and
          develop a website that will initialy serve as landing page for marketing
          purposes, but later we extended it’s functionalities so that it wasn’t
          presentational only, but functional as well.
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={tf1} alt="Our role in the chain" />
        <CaseStudyTextBlock title="Booth reservation">
          With custom interactive expo map, attendees could easily search through
          registered exhibitors. If you’re a company looking for a booth, click on
          available booth to reserve it. Fill out the Events sponsorship form and
          populated contract in PDF comes to your inbox before you say Token Fest!
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={tf2} alt="Our role in the chain" />
        <CaseStudyTextBlock title="Event agenda">
          With program organised in 3 days on 3 different stages, Token Fest
          needed a page to display all panels organised as much as possible, and
          we came up with a mobile-ready and smooth interface. Every panel had
          speakers displayed in form of their pictures, where click on each would
          lead to speaker profile with his biography.
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={tf3} alt="Our role in the chain" />
        <CaseStudyTextBlock title="Backend made easy">
          Wordpress is worlds’ most popular content management system, so it was
          obvious we’ve chosen it. Event organizers could manage website content
          without knowing a single line of code. Customised dashboard supported
          editing speaker profiles, sponsor logos, event schedule or any other
          copy on website.
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={tf4} alt="Our role in the chain" />
        <CaseStudyTextBlock title="On-site graphics">
          Apart from website design, we’ve been responsible for all graphics and
          print on the conferences. From event brochure, banners and easel signs,
          housekeeping presentation slides, up to attendee badges and social media
          graphics.
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={tf5} alt="Our role in the chain" />
      </div>
    </React.Fragment>
  );
};

export default TokenfestPage;
