import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const animDuration = 0.8;

export default class CaseStudyVideoBlock extends React.Component {
  render() {
    return (
      <section className="cs-video">
        <ScrollAnimation animateIn="fadeInUp" duration={animDuration}>
          <div className="container">
            <div className="video-wrapper">{this.props.children}</div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}
