import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import mockup from "../../img/header_mockup.jpeg";

class Header extends React.Component {
  render() {
    return (
      <section id="hero">
        <ScrollAnimation
          duration={this.props.animDuration}
          animateIn="fadeInUp"
        >
          <div className="container">
            <div className="hero-headline">
              <h1>
                We design &amp; develop
                <br />
                custom web apps.
              </h1>
            </div>
            <img className="hero-img" src={mockup} />
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default Header;
