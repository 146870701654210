import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const animDuration = 0.7;

export default class CaseStudyTextBlock extends React.Component {
  render() {
    return (
      <section className="cs-text">
        <ScrollAnimation animateIn="fadeInUp" duration={animDuration}>
          <div className="container">
            <h3>{this.props.title}</h3>
            <p>{this.props.children}</p>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}
