import React from "react";
import w from "../../img/w.svg";

class Footer extends React.Component {
  state = {
    footerHeight: 0
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.winResize);
    this.winResize();
  };

  winResize = () => {
    const bounds = document
      .getElementsByTagName("footer")[0]
      .getBoundingClientRect();
    this.setState({ footerHeight: bounds.height });
  };

  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="container">
            <div className="footer-row-1">
              <div className="footer-logo">
                <img src={w} alt="Winner Agency" />
              </div>
              <ul>
                <li className="list-title">Contact</li>
                <li>
                  <a href="mailto:hello@winner.agency">hello@winner.agency</a>
                </li>
              </ul>
              <ul>
                <li className="list-title">Services</li>
                <li>
                  <a href="/services">Product design</a>
                </li>
                <li>
                  <a href="/services#web">Web development</a>
                </li>
                <li>
                  <a href="/services#graphic">Graphic design</a>
                </li>
              </ul>
              <ul>
                <li className="list-title">Social</li>
                <li>
                  <a
                    href="https://www.facebook.com/winner.zg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/the.winner.agency/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
        <div
          className="footer-ghost"
          style={{ height: this.state.footerHeight }}
        ></div>
      </React.Fragment>
    );
  }
}

export default Footer;
