import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const animDuration = 0.8;

export default class CaseStudyImageBlock extends React.Component {
  render() {
    return (
      <section className="cs-image">
        <ScrollAnimation animateIn="fadeInUp" duration={animDuration}>
          <div className="container">
            <img src={this.props.src} alt={this.props.alt} />
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}
