import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo_horizontal.svg";
import logoW from "../../img/logo_horizontal_w.svg";
import ScrollAnimation from "react-animate-on-scroll";

class Header extends React.Component {
  render() {
    return (
      <ScrollAnimation
        animateIn="fadeInDown"
        offset="0"
        animateOnce={true}
        duration={0.5}
      >
        <header className="site-header">
          <div className="container">
            <Link to="/">
              <img
                id="logo"
                src={this.props.white ? logoW : logo}
                alt="Winner Agency"
              />
            </Link>
            <Link to="/contact" className="btn btn--yellow btn--basic">
              Start a project
            </Link>
          </div>
        </header>
      </ScrollAnimation>
    );
  }
}

export default Header;
