import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import CaseMini from "./CaseMini";
import privacywaveImage from "../../img/casestudies/privacywave/privacywave.png";
import tokenfestImage from "../../img/casestudies/tokenfest/tokenfest.png";
import ashelementsImage from "../../img/casestudies/ashelements/ashelements.png";

class RecentWork extends React.Component {
  render() {
    return (
      <section id="recent" className="home-section">
        <ScrollAnimation
          duration={this.props.animDuration}
          animateIn="fadeInUp"
        >
          <a name="projects" />
          <div className="container">
            <h3 className="section-title">Recent Work</h3>
            <CaseMini
              codeName="tokenfest"
              name="TokenFest"
              key="2"
              title="What’s common between Blockchain and Wordpress?"
              services={["Web Design", "Web Development", "On-site graphics"]}
              image={tokenfestImage}
            />
             <CaseMini
              codeName="privacy-wave"
              name="PrivacyWave"
              key="1"
              title="Erase your digital footprint with PrivacyWave"
              services={["Branding", "Web Design", "Web Development"]}
              image={privacywaveImage}
            />
            <CaseMini
              codeName="ashelements"
              name="AshElements"
              key="3"
              title="Ash Elements - Minimum Viable Product"
              services={["Web Design", "Web Development", "Graphic Design"]}
              image={ashelementsImage}
            />
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default RecentWork;
