import React from "react";
import Header from "../components/sections/Header";
import FormControlMessage from "../components/utils/FormControlMessage";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import Spacer from "../components/utils/Spacer";
import { Helmet } from "react-helmet";

import thumbnail from "../thumbnails/thumbnail.png";
import success_icon from "../img/success.svg";

const API_PATH = './email-script/index.php';

class ContactPage extends React.Component {
  state = {
    submitted: false,
    loading: false,
    formMessage: "",
    sent: false,
    name: {
      value: "",
      error: "Name is mandatory!"
    },
    email: {
      value: "",
      error: "Email is mandatory!"
    },
    message: {
      value: "",
      error: "Message is mandatory!"
    }
  };

  validationFailed = () => {
    return (
      !!this.state.name.error ||
      !!this.state.email.error ||
      !!this.state.message.error
    );
  };

  validate = (fieldName, value) => {
    let message = "";
    switch (fieldName) {
      case "name":
        if (!(value.length >= 3)) message = "Name is mandatory!";
        break;
      case "email":
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
          message = "Email is mandatory!";
        break;
      case "message":
        if (!(value.length >= 3)) message = "Message is mandatory!";
        break;
      default:
        break;
    }
    return message;
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      return {
        touched: true,
        [name]: {
          value: value,
          error: this.validate(name, value)
        }
      };
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    if (this.validationFailed()) return;

    this.setState(prevState => {
      return { loading: !prevState.loading, submitted: true };
    });

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: {
        "name": this.state.name.value,
        "email": this.state.email.value,
        "message": this.state.message.value
      }
    })
    .then(result => {
      this.setState(prevState => {
        return { loading: !prevState.loading, sent: true };
      });
    })
    .catch(error => {
      this.setState(prevState => {
        return { loading: false };
      });
      alert(`Network error. Please refresh the page and try again!`)
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="Got a project? Let's talk."
          />
          <meta
            name="keywords"
            content="winner, winner agency, contact, web app, websites, development, web development, design, node, nodejs, react, reactjs, wordpress, graphic design, product design, creative design, custom development, seo, php, javascript"
          />
          <meta property="og:image" content={thumbnail} />
          <link rel="canonical" href="https://winner.agency" />
        </Helmet>
        <Header white={false} />
        <div className="contact-page page">
          <div className="container">
            { this.state.sent &&
                <ScrollAnimation
                duration={0.5}
                animateIn="fadeInUp"
                animateOnce={true}
                >
                <React.Fragment>
                    <Spacer />
                    <img width="100px" src={success_icon} /><br /><br />
                    <h1>Thanks!</h1>
                    <p>We’ll get back to you shortly. Meanwhile, check out our <a href="https://www.instagram.com/the_winner_agency/">Instagram.</a></p>
                    <Spacer />
                </React.Fragment>
              </ScrollAnimation>
            }
            { !this.state.sent && 
            <React.Fragment>
              <ScrollAnimation
                duration={0.5}
                animateIn="fadeInUp"
                animateOnce={true}
              >
                <h1>Got a project? Let's talk!</h1>
                <p>
                  Drop us an email at{" "}
                  <a href="mailto:hello@winner.agency">hello@winner.agency</a> or
                  fill out the form below.
                </p>
                { this.state.loading && 
                  <ScrollAnimation
                  duration={0.5}
                  animateIn="fadeInUp"
                  animateOnce={true}
                  >
                  <div className="loading_msg">
                    Hang on! Your message is on the way...
                  </div>
                  </ScrollAnimation>
                }
              </ScrollAnimation>
              <ScrollAnimation duration={0.6} delay={200} animateIn="fadeInUp">
                <form onSubmit={this.onSubmit}>
                  <div className="form-row">
                    <div
                      className={
                        "form-control " +
                        (this.state.submitted &&
                          this.state.name.error &&
                          "form-control--error")
                      }
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Your name"
                        value={this.state.name.value}
                        onChange={this.handleUserInput}
                        autocomplete="off"
                      />
                      {this.state.submitted && this.state.name.error && (
                        <FormControlMessage message={this.state.name.error} />
                      )}
                    </div>
                    <div
                      className={
                        "form-control " +
                        (this.state.submitted &&
                          this.state.email.error &&
                          "form-control--error")
                      }
                    >
                      <input
                        type="email"
                        name="email"
                        placeholder="E-mail address"
                        value={this.state.email.value}
                        onChange={this.handleUserInput}
                      />
                      {this.state.submitted && this.state.email.error && (
                        <FormControlMessage message={this.state.email.error} />
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div
                      className={
                        "form-control " +
                        (this.state.submitted &&
                          this.state.message.error &&
                          "form-control--error")
                      }
                    >
                      <textarea
                        name="message"
                        placeholder="Few words about your project..."
                        value={this.state.message.value}
                        onChange={this.handleUserInput}
                      ></textarea>
                      {this.state.submitted && this.state.message.error && (
                        <FormControlMessage message={this.state.message.error} />
                      )}
                    </div>
                  </div>
                  <input
                    type="submit"
                    value="Send"
                    className="btn btn--yellow btn--basic"
                  ></input>
                </form>
              </ScrollAnimation>
            </React.Fragment>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactPage;
