import React from "react";
import error from "../../img/error.svg";

const FormControllMessage = props => {
  return (
    <div class="form-control-message">
      <img src={error} alt="Error" />
      <span>{props.message}</span>
    </div>
  );
};

export default FormControllMessage;
