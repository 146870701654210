import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import TokenfestPage from "../pages/TokenfestPage";
import PrivacywavePage from "../pages/PrivacywavePage";
import AshelementsPage from "../pages/AshelementsPage";
import ServicesPage from "../pages/ServicesPage";
import ContactPage from "../pages/ContactPage";
import Nav from "../components/sections/Nav";
import Footer from "../components/sections/Footer";
import ScrollToTop from "../routers/ScrollToTop";
import PageTransition from "../components/common/PageTransition";

class AppRouter extends React.Component {
  render = () => {
    return (
      <BrowserRouter>
        <Route
          render={({ location }) => {
            const { pathname, key } = location;

            return (
              <React.Fragment>
                <Nav />
                <ScrollToTop>
                  <Switch location={location}>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/index.html" exact component={HomePage} />
                    <Route path="/tokenfest/" component={TokenfestPage} />
                    <Route path="/privacy-wave/" component={PrivacywavePage} />
                    <Route path="/ashelements/" component={AshelementsPage} />
                    <Route path="/services/" component={ServicesPage} />
                    <Route path="/contact/" component={ContactPage} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ScrollToTop>
                <Footer />
              </React.Fragment>
            );
          }}
        />
      </BrowserRouter>
    );
  };
}

export default AppRouter;
