import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { Redirect } from "react-router";
import btnArrow from "../../img/btn_arrow.svg";

const animDuration = 800;

class CaseMini extends React.Component {
  state = {
    openCaseStudy: false,
    caseStudyOpened: false
  };

  playCurtain = () => {
    const bounds = ReactDOM.findDOMNode(this).getBoundingClientRect();
    this.setState({
      openCaseStudy: true,
      top: bounds.top,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height
    });

    setTimeout(() => {
      this.setState({
        caseStudyOpened: true
      });
    }, animDuration);
  };

  render() {
    if (this.state.caseStudyOpened) {
      return <Redirect push to={this.props.codeName} />;
    }

    return (
      <div className={`case-mini ${this.props.codeName}-bg`}>
        <CSSTransition
          in={this.state.openCaseStudy}
          timeout={animDuration}
          classNames="case-curtain"
        >
          <div
            className="case-curtain"
            style={{
              width: this.state.width,
              height: this.state.height,
              top: this.state.top,
              left: this.state.left
            }}
          ></div>
        </CSSTransition>

        <div className="case-mini__image">
          <img src={this.props.image} alt={this.props.name} />
        </div>
        <div className="case-mini__content">
          <ul>
            {this.props.services.map(service => {
              return <li key={service}>{service}</li>;
            })}
          </ul>
          <h4>{this.props.title}</h4>
          <button
            className="btn btn--yellow btn--arrow"
            onClick={this.playCurtain}
          >
            <span>Case Study</span>
            <img src={btnArrow} alt="Open Case Study" />
          </button>
        </div>
      </div>
    );
  }
}

export default CaseMini;
