import React from "react";
import { Link } from "react-router-dom";

class NavItem extends React.Component {
  render() {
    return (
      <li style={{ animationDelay: this.props.delay }}>
        <Link
          to={this.props.to}
          style={{ animationDelay: this.props.delay }}
          onClick={this.props.onClick}
        >
          {this.props.label}
        </Link>
      </li>
    );
  }
}

export default NavItem;
