import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import CaseStudyTextBlock from "../components/sections/CaseStudyTextBlock";
import CaseStudyVideoBlock from "../components/sections/CaseStudyVideoBlock";
import CaseStudyImageBlock from "../components/sections/CaseStudyImageBlock";
import aeFeatured from "../img/casestudies/ashelements/ae-featured.png";
import ae1 from "../img/casestudies/ashelements/ae-mockup-1.png";
import ae2 from "../img/casestudies/ashelements/ae-mockup-2.png";
import Header from "../components/sections/Header";
import { Helmet } from "react-helmet";
import thumbnail from "../thumbnails/ashelements.png";

const AshelementsPage = () => {
  return (
    <React.Fragment>
    <Helmet>
        <title>Ash Elements - Case Study</title>
        <meta
          name="description"
          content="Main goal of Ash Elements website was to present it’s mission, the team and core values. Besides presentational line..."
        />
        <meta
          name="keywords"
          content="wordpress, php, blockchain, mvp, minimum viable product, ash elements, web development, winner"
        />
        <meta property="og:image" content={thumbnail} />
      </Helmet>
      <div className="cs-page">
        <ScrollAnimation animateIn="fadeInDown" offset="0" animateOnce={true}>
          <Header white={true} />
        </ScrollAnimation>
        <header className="casestudy ashelements-bg ashelements-color">
          <ScrollAnimation animateIn="fadeInUp">
            <div className="container">
              <div className="cs-header--content">
                <h4>Case Study</h4>
                <h1>Ash Elements - Minimum Viable Product</h1>
                <div className="cs-infobox">
                  <span>Client</span>
                  <ul>
                    <li>Ash Elements</li>
                  </ul>
                </div>
                <div className="cs-infobox-separator"></div>
                <div className="cs-infobox">
                  <span>Services</span>
                  <ul>
                    <li>Web design</li>
                    <li>Web development</li>
                    <li>Graphic design</li>
                  </ul>
                </div>
              </div>

              <div className="cs-header--featured">
                <img src={aeFeatured} alt="AshElements Mobile Web" />
              </div>
            </div>
          </ScrollAnimation>
        </header>
        <CaseStudyTextBlock title="About the client">
          The mission of Ash Elements is to turn an ugly liability into an
          attractive environmentally responsible asset. This asset will be made
          available to the blockchain community and in doing so add value in many
          dimensions to our collective future. In order to prove it’s concepts and
          attract early investors, Ash Elements needed a minimum viable product in
          form of a simple web application to register ash basins. To find out
          more about Ash Element’s vision, check out the explainer video below.
          <br />
          <br />
          To find out more about Ash Element’s vision, check out the explainer
          video below.
        </CaseStudyTextBlock>
        <CaseStudyVideoBlock>
          <iframe
            width="560"
            height="315"
            fov="100"
            src="https://www.youtube.com/embed/q6UmxSkCOpg"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </CaseStudyVideoBlock>
        <CaseStudyTextBlock title="Scope of work">
          Main goal of Ash Elements website was to present it’s mission, the team
          and core values. Besides presentational line, website has user
          management system where users can register with ERC20 wallet, follow
          prices tranding of rare earth elements and see ash basin locations. To
          achieve the latter, we’ve built a Wordpress powered website that allowed
          administrators to manage website copy, users and database objects
          (wallet addresses, basin locations, documents, etc.).
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={ae1} alt="AshElements scope of work" />
        <CaseStudyTextBlock title="Continued support">
          Since Ash Elements had dozen of scheduled conferences ahead, our
          cooperation didn’t end by the date of website launch. We’ve provided
          continued support on both web and graphic design fields, making
          continous updates on the website per their request and designing visuals
          for on-site marketing..
        </CaseStudyTextBlock>
        <CaseStudyImageBlock src={ae2} alt="AshElements responsive website" />
      </div>
    </React.Fragment>
  );
};

export default AshelementsPage;
