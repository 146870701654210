import React from "react";
import { CSSTransition } from "react-transition-group";

class PageTransition extends React.Component {
  state = {
    visible: true
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
  };

  render() {
    return (
      <CSSTransition in={this.state.visible} timeout={5000} unmountOnExit>
        <div className="page-transition"></div>
      </CSSTransition>
    );
  }
}

export default PageTransition;
