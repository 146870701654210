import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Client from "./Client";
import rimac from "../../img/logos/rimac.png";
import tokenfest from "../../img/logos/tokenfest.png";
import koios from "../../img/logos/koios.png";
import gdi from "../../img/logos/gdi.png";
import blockhires from "../../img/logos/blockhires.png";
import nextlex from "../../img/logos/nextlex.png";
import crowdrabbit from "../../img/logos/crowdrabbit.png";
import agile from "../../img/logos/agile.png";

export default class Clients extends React.Component {
  render() {
    return (
      <section id="clients" className="home-section">
        <ScrollAnimation
          duration={this.props.animDuration}
          animateIn="fadeInUp"
        >
          <div className="container">
            <h3 className="section-title">Clients</h3>
            <div className="client-logos">
              <Client
                name="Rimac Automobili"
                url="https://www.rimac-automobili.com/"
                logo={rimac}
              />
              <Client
                name="Tokenfest"
                url="http://tokenfest.io"
                logo={tokenfest}
              />
              <Client name="Koios" url="https://www.koios.hr" logo={koios} />
              <Client name="GDi" url="https://gdi.net/" logo={gdi} />
              <Client
                name="Blockhires"
                url="http://blockhires.com/"
                logo={blockhires}
              />
              <Client name="Nextlex" url="https://nextlex.ch/" logo={nextlex} />
              <Client
                name="Crowdrabbit"
                url="https://www.facebook.com/crowdrabbit/"
                logo={crowdrabbit}
              />
              <Client
                name="Agile on target"
                url="http://www.agileontarget.com/"
                logo={agile}
              />
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}
