import React from "react";
import NavItem from "./NavItem";
import { CSSTransition } from "react-transition-group";
import HamburgerButton from "../common/HamburgerButton";
const navAnimDuration = 600;

class Nav extends React.Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState(prevState => {
      return { open: !prevState.open };
    });
  };

  hideNav = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <nav className={this.state.open ? "nav--opened" : "nav--closed"}>
        <div id="nav__ribbon">
          <HamburgerButton handleClick={this.toggle} open={this.state.open} />
        </div>
        <CSSTransition
          in={this.state.open}
          timeout={navAnimDuration}
          classNames="nav__content"
          unmountOnExit
          >
          <div id="nav__content">
            <ul>
              <div className="container">
                <NavItem
                  to="/"
                  delay="0.2s"
                  label="Home"
                  onClick={this.hideNav}
                />
                <NavItem 
                  to="/services"
                  delay="0.3s" 
                  label="Services" 
                  onClick={this.hideNav}/>
                {/* <NavItem 
                  to="#projects"
                  delay="0.4s" 
                  label="Projects"
                  onClick={this.hideNav} />
                <NavItem delay="0.5s" label="Clients" /> */}
                <NavItem
                  to="/contact"
                  delay="0.4s"
                  label="Contact"
                  onClick={this.hideNav}
                />
              </div>
            </ul>
            <div className="nav__contact" style={{ animationDelay: "0.7s" }}>
              <div className="container">hello@winner.agency</div>
            </div>
          </div>
        </CSSTransition>
      </nav>
    );
  }
}

export default Nav;
