import React from "react";
import loaderVideo from "../../img/loaders/animation.mp4";

const FullPageLoader = () => {
  return (
    <section className="full-page-loader">
      <video autoPlay loop muted className="banner__video">
        <source src={loaderVideo} type="video/mp4" />
      </video>
    </section>
  );
};

export default FullPageLoader;
