import React from "react";
import Header from "../components/sections/Header"
import { Link } from "react-router-dom";

// Illustrations
import productIllustration from "../img/services/illustration_product.svg"
import webIllustration from "../img/services/illustration_web.svg"
import graphicIllustration from "../img/services/illustration_graphic.svg"
import specialIllustration from "../img/services/illustration_special.svg"

// Icons
import xd_icon from "../img/services/icon_xd.svg"
import node_icon from "../img/services/icon_node.svg"
import react_icon from "../img/services/icon_react.svg"
import js_icon from "../img/services/icon_js.svg"
import php_icon from "../img/services/icon_php.svg"
import wp_icon from "../img/services/icon_wp.svg"
import ps_icon from "../img/services/icon_ps.svg"
import ae_icon from "../img/services/icon_ae.svg"
import secret_icon from "../img/services/icon_secret.svg"
import { Helmet } from "react-helmet";
import thumbnail from "../thumbnails/thumbnail.png";

const ServicesPage = () => {
  return (
    <React.Fragment>
      <Helmet>
          <title>Services</title>
          <meta
            name="description"
            content="Product design, Web development, Graphic and creative design..."
          />
          <meta
            name="keywords"
            content="winner, winner agency, web app, websites, development, web development, design, node, nodejs, react, reactjs, graphic design, product design, creative design, custom development, seo, php, javascript"
          />
          <meta property="og:image" content={thumbnail} />
          <link rel="canonical" href="https://winner.agency" />
      </Helmet>
      <Header white={false} />
      <div className="services-page page">
        <div className="container">
          <h1>Services</h1>
          <div className="service-container">
            <a name="product" />
            <div className="left">
              <img src={productIllustration} />
            </div>
            <div className="right">
              <h3>PRODUCT DESIGN</h3>
              <p>
              Product design involves everything from ideation and brainstorming to development and product launch, but best word to describe it is prototyping. If you have an idea about the web or mobile product but need a partner to make it happen, we’re here to help you visualise, design, and prepare your project for development phase.
              </p>
              <div className="tools">
                <div className="item"><img src={xd_icon} /></div>
              </div>
              <Link to="/contact" className="btn btn--yellow btn--basic">
                Inquire
              </Link>
            </div>
          </div>
          <div className="service-container">
           <a name="web" />
            <div className="left">
              <img src={webIllustration} />
            </div>
            <div className="right">
              <h3>WEB DEVELOPMENT</h3>
              <p>
                Once we know what we’re going to build and it’s visually defined, it’s time to bring it to life. Our developers are here to build sustainable websites and web apps that meet all the initial requirements. We operate with the most popular technologies of today to ensure best performance.
              </p>
              <div className="tools">
                <div className="item"><img src={react_icon} /></div>
                <div className="item"><img src={js_icon} /></div>
                <div className="item"><img src={node_icon} /></div>
                <div className="item"><img src={php_icon} /></div>
                <div className="item"><img src={wp_icon} /></div>
              </div>
              <Link to="/contact" className="btn btn--yellow btn--basic">
                Inquire
              </Link>
            </div>
          </div>
          <div className="service-container">
            <a name="graphic" />
            <div className="left">
              <img src={graphicIllustration} />
            </div>
            <div className="right">
              <h3>GRAPHIC & CREATIVE DESIGN</h3>
              <p>
              If you’re in need of a graphic design services, you’re at the right place. With more than 7 years of experience in this field, our designers are here to design everything your business might need, is it just a social media graphic of full design service for an event - we’re here to make it stand out.
              </p>
              <div className="tools">
                <div className="item"><img src={ps_icon} /></div>
                <div className="item"><img src={ae_icon} /></div>
              </div>
              <Link to="/contact" className="btn btn--yellow btn--basic">
                Inquire
              </Link>
            </div>
          </div>
          <div className="service-container">
            <a name="special" />
            <div className="left">
              <img src={specialIllustration} />
            </div>
            <div className="right">
              <h3>SPECIAL MISSIONS</h3>
              <p>
                If you’re thinking ahead of time and the service you’re looking for isn’t listed here, let us know and we’ll see if we can figure something out. Our team is always up for a new (classified) challenges.
              </p>
              <div className="tools">
                <div className="item"><img src={secret_icon} /></div>
              </div>
              <Link to="/contact" className="btn btn--yellow btn--basic">
                Inquire
              </Link>
            </div>
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServicesPage;
