import React from "react";
import Header from "../components/sections/Header";
import Spacer from "../components/utils/Spacer";
import illustration from "../img/404.svg";
import ScrollAnimation from "react-animate-on-scroll";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Header white={false} />
      <div className="notfound-page page">
        <div className="container">
          <ScrollAnimation
                duration={0.5}
                animateIn="fadeInUp"
                animateOnce={true}
                >
            <img src={illustration} width="300px" /> 
            <h1>Page not found</h1>   
            <h3>We looked literally everywhere. <a href="/" style={{"color":"black"}}>Take me home.</a></h3>  
            
          </ScrollAnimation>
        </div>
      </div>
    </React.Fragment>
    
  );
};

export default NotFoundPage;
